import React from 'react';
import Layout from 'components/layout';
import { Link } from 'gatsby';
import styled from 'styled-components';

export const CenterItems = styled.div`
  text-align: center;
  height: 50vh;
  width: 60%;
  margin: 10% auto 0 auto;
`;

const NotFound = () => (
  <Layout>
    <CenterItems>
      <p>
        Some places are great to lose yourself in, but not on this occasion.
        <br />
        You could try a menu link or return to our <Link to="/">Home page</Link>
        .
      </p>
    </CenterItems>
  </Layout>
);

export default NotFound;
